.logo-1 {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 8rem;
  height: 8rem;
  z-index: 99;
}

.logo-2 {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 8rem;
  height: 8rem;
  z-index: 99;
}

.logo-3 {
   width: 15rem !important;
  height: 15rem !important;
}

.img {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.img-rounded {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  text-align: center;
}

.img-container {
  border-radius: 10px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
