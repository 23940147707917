.nav-icon {
  color: #212121;
  font-size: 2rem;
  font-weight: bold;
  height: 2rem;
  width: 2rem;
  background-color: rgba(255, 255,255, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 100%;
  //box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  //0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  text-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.nav-back {
  position: absolute;
  top: 50%;
  left: 5px;
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.nav-next {
  position: absolute;
  top: 50%;
  right: 5px;
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}
