// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons");
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pro-eye-primary: mat-palette($mat-indigo, 900);
$pro-eye-accent: mat-palette($mat-green, 800, 900, 500);

// The warn palette is optional (defaults to red).
$pro-eye-warn: mat-palette($mat-red, 600, 700, 500);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pro-eye-theme: mat-light-theme(
  (
    color: (
      primary: $pro-eye-primary,
      accent: $pro-eye-accent,
      warn: $pro-eye-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($pro-eye-theme);

/* You can add global styles to this file, and also import other style files */

@import "sass/abstracts/functions";
@import "sass/abstracts/mixins";
@import "sass/abstracts/variables";

@import "sass/base/animations";
@import "sass/base/base";
@import "sass/base/typography";
@import "sass/base/utilities";

@import "sass/components/button";
@import "sass/components/card";
@import "sass/components/composition";
@import "sass/components/feature-box";
@import "sass/components/form";
@import "sass/components/input";
@import "sass/components/logo";
@import "sass/components/messages-icon";
@import "sass/components/nav-icon";
@import "sass/components/popup";
@import "sass/components/spinner";
@import "sass/components/story";

@import "sass/layout/backdrop";
@import "sass/layout/container";
@import "sass/layout/dashboard-header";
@import "sass/layout/footer";
@import "sass/layout/grid";
@import "sass/layout/header";
@import "sass/layout/navigation";
@import "sass/layout/table";

@import "sass/pages/home";
