.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.32);
  -webkit-user-select: none;
  -moz-user-select: none;
}

.backdrop-40 {
  position: fixed;
  top: 0;
  left: 60vw;
  bottom: 0;
  right: 0;
  z-index: 60;
  background-color: rgba(0, 0, 0, 0.32);
  user-select: none;
}
