// COLORS
$color-primary: #1e3463;
$color-tertiary-light: #f8f7fd;
$color-success: #00796b;
$color-warn: #ef6c00;
$color-danger: #dd2c00;
$color-grey-light: #f7f7f7;
$color-grey-dark: #777;

// 122758
// 1e3463
