@import "./../abstracts/variables";

.text {
  &-1 {
    @include textStyle(400, 1rem);
  }

  &-2 {
    @include textStyle(400, 1.5rem);
  }
  &-3 {
    @include textStyle(400, 2rem);
  }
  &-4 {
    @include textStyle(400, 2.5rem);
  }
  &-5 {
    @include textStyle(400, 3rem);
  }
  &-6 {
    @include textStyle(400, 3.5rem);
  }
  &-7 {
    @include textStyle(400, 4rem);
  }
  &-8 {
    @include textStyle(400, 4.5rem);
  }
  &-9 {
    @include textStyle(400, 5rem);
  }
}

.text-bold {
  &-1 {
    @include textStyle(700, 1rem);
  }
  &-13 {
    @include textStyle(700, 1.3rem);
  }

  &-2 {
    @include textStyle(700, 1.5rem);
  }
  &-3 {
    @include textStyle(700, 2rem);
  }
  &-4 {
    @include textStyle(700, 2.5rem);
  }
  &-5 {
    @include textStyle(700, 3rem);
  }
  &-6 {
    @include textStyle(700, 3.5rem);
  }
  &-7 {
    @include textStyle(700, 4rem);
  }
  &-8 {
    @include textStyle(700, 4.5rem);
  }
  &-9 {
    @include textStyle(700, 5rem);
  }
}

.text-primary {
  color: $color-primary;
}
.text-success {
  color: $color-success !important;
}
.text-warn {
  color: $color-warn;
}
.text-danger {
  color: $color-danger;
}
.text-light {
  color: $color-grey-light;
}
.text-dark {
  color: $color-grey-dark;
}
.text-darker {
  color: rgb(49, 49, 49);
}
.text-white {
  color: #fff;
}

.text-shadow-dark {
  text-shadow: 2px 2px 0 #bcbcbc, 4px 4px 0 #9c9c9c !important;
}

.text-shadow-light {
  text-shadow: 0px 3px 3px rgb(200, 200, 200) !important;
}
.text-shadow-custom {
  text-shadow: 2px 2px 2px rgb(80, 80, 80);
}
.text-shadow {
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.font-custom {
  font-family: "El Messiri", sans-serif !important;
}
