.warn-btn {
  background-color: $color-warn !important;
  color: #fff !important;
}

.cancel-btn {
  background-color: $color-danger !important;
  color: #fff !important;
}

.btn-rounded {
  border-radius: 5rem !important;
}
