.w-fit {
  width: fit-content !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}.w-10
 {
  width: 10% !important;
}

.w-100v {
  width: 100vw !important;
}

.w-90v {
  width: 90vw !important;
}

.w-80v {
  width: 80vw !important;
}

.w-70v {
  width: 70vw !important;
}

.w-60v {
  width: 60vw !important;
}

.w-50v {
  width: 50vw !important;
}

.w-40v {
  width: 40vw !important;
}

.w-30v {
  width: 30vw !important;
}

.w-20v {
  width: 20vw !important;
}

// height
.h-fit {
  height: fit-content !important;
}

.h-100 {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.h-80 {
  height: 80% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-60 {
  height: 60% !important;
}

.h-50 {
  height: 50% !important;
}

.h-40 {
  height: 40% !important;
}

.h-30 {
  height: 30% !important;
}

.h-25 {
  height: 25% !important;
}
.h-20 {
  height: 20% !important;
}
.h-15 {
  height: 15% !important;
}

.h-100v {
  height: 100vh !important;
}

.h-90v {
  height: 90vh !important;
}

.h-80v {
  height: 80vh !important;
}
.h-85v {
  height: 85vh !important;
}

.h-70v {
  height: 70vh !important;
}

.h-75v {
  height: 75vh !important;
}

.h-60v {
  height: 60vh !important;
}

.h-50v {
  height: 50vh !important;
}

.h-40v {
  height: 40vh !important;
}

.h-30v {
  height: 30vh !important;
}

.h-25v {
  height: 25vh !important;
}
.h-20v {
  height: 20vh !important;
}
// end height
.text-center {
  text-align: center !important;
}
.text-start {
  text-align: start !important;
}.text-end {
  text-align: end !important;
}

.bg-primary {
  background-color: $color-primary !important;
}
.bg-tertiary {
  background-color: $color-tertiary-light !important;
}
.bg-success {
  background-color: $color-success !important;
}
.bg-warn {
  background-color: $color-warn !important;
}
.bg-danger {
  background-color: $color-danger !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-light {
  background-color: $color-grey-light !important;
}
.bg-dark {
  background-color: $color-grey-dark !important;
}

.box-shadow-down {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.box-shadow-top {
  box-shadow: 0 -3px 3px 4px rgba(0, 0, 0, 0.3);
}

.box-shadow-1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.box-shadow-2 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.overflow-hidden {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.y-auto {
  overflow-y: auto !important;
}
.y-hidden {
  overflow-y: hidden !important;
}

.x-auto {
  overflow-x: auto !important;
}

.x-hidden {
  overflow-x: hidden !important;
}

.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-12 {
  margin-bottom: 1.2rem !important;
}

.mb-15 {
  margin-bottom: 1.5rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
