*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

body {
  box-sizing: border-box;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// media query
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 56.25em) {
  body {
    padding: 0;
  }
}

// image
img {
  user-select: none !important;
  -webkit-user-select: none;
}

p {
  margin-bottom: 0 !important;
}

// scrollbar
/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e3463;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($color: #1e3463, $amount: 20%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

input {
  overflow: visible !important;
  line-height: normal !important;
}
input::placeholder {
  overflow: visible !important;
  line-height: normal !important;
}
