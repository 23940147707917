.input {
  font-family: "El Messiri", sans-serif !important;
  display: inline-block;
  width: 100%;
  color: #1e3463;
  border: none;
  outline: none;
  text-align: center;
  margin: 0 1.1rem 0 1.5rem;
}

.input-edit {
  font-family: "El Messiri", sans-serif !important;
  display: inline-block;
  width: 100%;
  color: $color-success;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  outline: none;
  margin: 0 1.1rem 0 1.5rem;
  text-align: center;
}

.app-data-line {
  width: 100% !important;
  min-width: 330px !important;
  position: relative !important;
  color: #1e3463;
}

.mat-dialog-content {
  width: 40vw;
  min-width: 500px !important;
  max-width: 800px !important;
  text-align: center !important;
  padding: 2rem !important;
}
